/*
Since same repo is used for roimatic and stunnr (CB), there'll be an additional commit on cb-develop and cb-master
  Master, Staging and Develop -> Roimatic's Client App Id
  Cb-Master and Cb-Develop -> Stunnr's Client App Id

We're getting the axios instance from the submodule -> creativeBuilderTools, which is not handling any exceptions
  We need to intercept and handle the exception
*/

import apiAxiosInstance from '@deltax/creativebuilder-tools/services/api';
import { handleError } from './apiErrorHandler';

apiAxiosInstance.interceptors.response.use(
  response => response,
  error => handleError(error)
);

export default apiAxiosInstance;
export { apiMethods } from '@deltax/creativebuilder-tools/services/api';
