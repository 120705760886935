import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import AppConfig from 'Constants/AppConfig';
import Vue from 'vue'
import { InvalidAccessTokenError } from './errors/InvalidAccessTokenError'
import { SessionNotFoundException } from './errors/SessionNotFoundException';

export const SupressedErrors = Object.freeze([
  InvalidAccessTokenError,
  SessionNotFoundException
]);

export const BugsnagConfig = {
  configure() {
    Bugsnag.start({
      apiKey: AppConfig.bugsnagApiKey,
      plugins: [new BugsnagPluginVue(Vue)],
      releaseStage: process.env.VUE_APP_RELEASE_STAGE,
      onError: (event) => {
        if (event && event.originalError) {
          const originalError = event.originalError;

          // Check for InvalidAccessTokenError
          if (SupressedErrors.includes(originalError.constructor)) {
            return false;
          }

          // Check for NavigationDuplicated error message
          if (originalError.message && originalError.message.includes('Avoided redundant navigation to current location')) {
            return false;
          }
        }
        return true;
      }
    })
  }
}